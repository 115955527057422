import { LoginComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadChildren: "./home/home.module#HomePageModule",
    runGuardsAndResolvers: "always",
  },
  {
    path: "list/:id",
    canActivate: [AuthGuard],
    loadChildren: "./list/list.module#ListPageModule",
    runGuardsAndResolvers: "always",
  },
  {
    path: "preview",
    loadChildren: "./list/list.module#ListPageModule",
    runGuardsAndResolvers: "always",
  },
  {
    path: "document",
    loadChildren: "./document/document.module#DocumentModule",
    runGuardsAndResolvers: "always",
  },
  {
    path: "docket",
    loadChildren: "./docket/docket.module#DocketModule",
    runGuardsAndResolvers: "always",
  },
  {
    path: "patent",
    loadChildren: "./patent/patent.module#PatentModule",
    runGuardsAndResolvers: "always",
  },
  {
    path: "login",
    component: LoginComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "register-user",
    component: SignUpComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "verify-email-address",
    component: VerifyEmailComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "**",
    component: PageNotFoundComponent,
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , onSameUrlNavigation: 'reload', enableTracing: false})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
