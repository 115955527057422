// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCyAwkyMSmrNJsOej3EuayYgn3_Fx8N2Y8',
    authDomain: 'patentphd-a9345.firebaseapp.com',
    databaseURL: 'https://patentphd-a9345.firebaseio.com',
    projectId: 'patentphd-a9345',
    storageBucket: '',
    messagingSenderId: '291391508378',
    appId: '1:291391508378:web:6d4103854d82c226'
  },
  courtListener: {
    apiToken: '369c191f7bbdcb194d9b088d03379f3ac179ef1e',
    pacerUserID: 'LEXLAW.io',
    pacerPassWD: 'Pacer.ar1esTarr1ng',
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
