import { Component, OnInit } from '@angular/core';
import { MenuServiceService } from '../shared/menu-service.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  public showRedirect: boolean = false;
  constructor(public menu: MenuServiceService) { }

  ngOnInit() {
    setTimeout(() => {
      this.showRedirect = true
    }, 5000);
  }
  toggle() {
    this.menu.toggleMenu()
  }
}
