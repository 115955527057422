import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { getLocaleDateFormat } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DocumentListService {

  // Observable string sources
  private documentAnnouncedSource = new Subject<any>();
  public documentSource: string;
  public previousSearchText: string;
  private subscriber: any;
  public documentList: any;
  private groupAnnouncedSource = new Subject<any>();
  private searchTextSource = new Subject<any>();
  // Observable string streams
  documentAnnounced$ = this.documentAnnouncedSource.asObservable();
  groupAnnounced$ = this.groupAnnouncedSource.asObservable();
  searchText$ = this.searchTextSource.asObservable();
  // Service message commands
  announceDocument(id: string, source?: string) {
    console.log(source + ' announced: ' + id);
    if (id !== this.documentSource && id !== (null || undefined)) {
      this.documentSource = id;
      this.getData(id);
    } else {
      console.log('responding to ' + source + ' with list')
      this.documentAnnouncedSource.next(this.documentList);
    }

  }
  announceGroup(group: any) {
    this.groupAnnouncedSource.next(group);
  }
  searchText(input: any, source: string){
    console.log(input + ' : ' + source)
    this.previousSearchText = input
    this.searchTextSource.next({input: input, source: source});
  }
  getData(id: string) {
    // console.log('getting data: ' + id);
    this.subscriber = this.db
      .list('content/' + id + '/pdfdata/bookmarks')
      .valueChanges();
    this.subscriber.subscribe(data => {
      // console.log('subscriber data')
      // console.log(data)
      this.documentList = data.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      this.documentAnnouncedSource.next(this.documentList);
    });
  }
  getMerits() {
    return this.documentList;
  }
  constructor(public db: AngularFireDatabase) { }
}
