import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MenuServiceService } from '../../shared/menu-service.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  constructor(public authService: AuthService, public menu: MenuServiceService, public title: Title) { }

  ngOnInit() {
    this.title.setTitle('Forgot Password - PatentPhD')
  }
  toggle() {
    this.menu.toggleMenu()
  }
}
