import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentFilter',
  pure: false
})
export class DocumentFilterPipe implements PipeTransform {

  transform(value: any, args: string): any {
    // console.log('ARGS')
    // console.log(args)
    return value.filter(doc => {
      return doc.tags.find(tag => tag.toLowerCase() === args.toLowerCase())})
  }

}
@Pipe({
  name: 'docFilter',
  pure: false
})
export class DocFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === undefined){
      return value
    }
    else{
      return value.filter(doc => {
        let t = JSON.stringify(doc).toLowerCase()
        return t.includes(args.toLowerCase())})
    }
  }

}
