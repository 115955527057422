import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './auth/auth.service';
import { MenuServiceService } from './shared/menu-service.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  conditionA: boolean;
  public currentUrl: string;
  public showMenu: boolean;
  userid: string | null;
  user: firebase.User;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    public authService: AuthService,
    public menu: MenuServiceService,
    public navCtrl: NavController
  ) {
    this.conditionA = this.menu.showMenu;
    this.showMenu = this.menu.showMenu;
    this.initializeApp();
    this.menu.showMenu$.subscribe(shouldI => this.conditionA = shouldI);
    this.authService.afAuth.user.subscribe(user => this.user = user);
  }
  shouldShow() {
    return this.conditionA
  }
  toggle(){
    this.menu.toggleMenu()
  }
  toggleMenuPrefs(currentValue: boolean){
    this.menu.toggleMenuPrefs(currentValue);
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
  redirectToLogin(){
    this.authService.redirectUrl = this.router.url;
    this.navCtrl.navigateForward('/login')
  }
}
