import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MenuServiceService } from '../../shared/menu-service.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {

  constructor(public authService: AuthService, public menu: MenuServiceService, public title: Title) { }

  ngOnInit() {
    this.title.setTitle('Sign Up - PatentPhD')
  }
  toggle() {
    this.menu.toggleMenu()
  }

}
