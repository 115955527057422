import { Pipe, PipeTransform } from '@angular/core';
import { values } from 'd3';

@Pipe({
  name: 'cssify'
})
export class CssifyPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    const t = '(';
    const u = ')';
    const _ = '-';
    if (value.includes(t)) {
      const values = value.split(t);
      const values1 = values[1].split(u);
      return 'c' + values[0] + values1[0]
    } else if(value.includes(_)){
      const values = value.split(_);
      return 'c' + values[0] + values[1]
    } else {
      const tt = new RegExp(/i\d+/ig);
      if (tt.test(value)){
        return 'issued'
      } else{
        return value.toLowerCase();

      }
    }

  }

}
