import { Injectable, NgZone } from '@angular/core';
import { auth } from 'firebase/app';
import { User } from '../shared/interfaces/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';

import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public db: AngularFireDatabase,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        console.log("authStateSubscribed");
        // console.log(user);
        localStorage.setItem('user', JSON.stringify(this.userData));
        this.localUser = JSON.parse(localStorage.getItem('user'));
    console.log('local user')
    console.log(this.localUser)
    this.accessToken = this.localUser.stsTokenManager.accessToken
    this.expirationTime = this.localUser.stsTokenManager.expirationTime
      } else {
        localStorage.setItem('user', null);

      }
    });
    this.localUser = JSON.parse(localStorage.getItem('user'));
    console.log('local user')
    console.log(this.localUser)
    this.accessToken = this.localUser.stsTokenManager.accessToken
    this.expirationTime = this.localUser.stsTokenManager.expirationTime

    if(this.expirationTime < Date.now()){
      this.router.navigate(['/login'])
    }

  }
  // isLoggedIn = false;

  // store the URL so we can redirect after logging in
  public redirectUrl: string;
  userData: any; // Save logged in user data
  localUser: any;
  public accessToken: string;
  public expirationTime: string | number | Date
  // login(): Observable<boolean> {
  //   return of(true).pipe(
  //     delay(1000),
  //     tap(val => this.isLoggedIn = true)
  //   );
  // }
  async login(email, password): Promise<Observable<boolean>> {
    const pop = await this.SignIn(email, password).then(() => {
      console.log('isLoggedIn');
      console.log(this.isLoggedIn());
      return true;
    });

    return of(pop);
    // this.isLoggedIn = true;
    // return of(true).pipe(delay(1000),tap(val => this.isLoggedIn = true));
  }

  logout(): void {
    this.SignOut();
    // this.isLoggedIn = false;
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        this.ngZone.run(() => {
          this.router.navigate([this.redirectUrl || 'home']);
        });
        // console.log("result");
        // console.log(result);
        // this.afAuth.auth.onAuthStateChanged(user => {
          if (result.user) {
            console.log("authChange");
            console.log(result.user);
            localStorage.setItem("user", JSON.stringify(result.user));
            // User is signed in.
            // ...
          } else {
            // User is signed out.
            // ...
          }
        // });
        // let token = result
        // console.log(token)
        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
      this.router.navigate(['verify-email-address']);
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch(error => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(result => {
        this.ngZone.run(() => {
          this.router.navigate([this.redirectUrl || 'home']);
        });
        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    console.log("user");
    console.log(user);
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userDb = this.db.object(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    userDb.update(userData);
    return userRef.set(userData, {
      merge: true
    });
  }
  UpdateProfile(userData: User) {
    const user = this.afAuth.auth.currentUser;

    user
      .updateProfile({
        displayName: userData.displayName,
        photoURL: userData.photoURL
      })
      .then(() => {
        // Update successful.
        this.SetUserData(userData);
      })
      .catch((error) => {
        // An error happened.
        alert(error);
      });
  }
  UpdateEmail(userData: User) {
    const user = this.afAuth.auth.currentUser;

    user
      .updateEmail(userData.email)
      .then(() => {
        // Update successful.
        this.SendVerificationMail();
        this.SetUserData(userData);
      })
      .catch((error) => {
        // An error happened.
        alert(error);
      });
  }
  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.userData = null;
      //this.router.navigate(['login']);
    });
  }
}
