import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MenuServiceService {
  private menuClickedSource = new Subject<boolean>();
  // Observable string streams
  showMenu$ = this.menuClickedSource.asObservable();
  // Menu Defaults
  public showMenu = JSON.parse(localStorage.getItem('defaultMenuOpen'));
  public toggleMenu(){
      this.showMenu = !this.showMenu;
      this.menuClickedSource.next(this.showMenu);
  }
  public toggleMenuPrefs(currentValue: boolean) {
    localStorage.setItem('defaultMenuOpen', JSON.stringify(!currentValue));
  }
  constructor() {}
}
