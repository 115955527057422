import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortStatus'
})
export class ShortStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {

              if (value !== undefined || null) {
                var ca = new RegExp(/currently.?amended/i);
                var pa = new RegExp(/previously.?amended/i);
                var pp = new RegExp(/previously.?presented/i);
                var c = new RegExp(/cancell?e?d?/i);
                var o = new RegExp(/original/i);
                var aa = new RegExp(/allow/i);
                var ab = new RegExp(/new/i);
                var w = new RegExp(/withdrawn/i);
                var e = new RegExp(/elected/i);
                var iss = new RegExp(/I\d+/i);
                var a101a = new RegExp(/\d+/i);
                var obj = new RegExp(/objection/i);
                var adlim = new RegExp(/REJ/);
                if (aa.test(value) == true) {
                  return "A";
                }
                if (ab.test(value) == true) {
                  return "NEW";
                }
                if (ca.test(value) == true) {
                  return "CA";
                }
                if (pa.test(value) == true) {
                  return "PA";
                }
                if (pp.test(value) == true) {
                  return "PP";
                }
                if (c.test(value) == true) {
                  return "C";
                }
                if (o.test(value) == true) {
                  return "O";
                }
                if (w.test(value) == true) {
                  return "W";
                }
                if (e.test(value) == true) {
                  return "E";
                }
                if (iss.test(value) == true) {
                  return value.slice(1);
                }
                if (a101a.test(value) == true) {
                  return value;
                }
                if (obj.test(value) == true) {
                  return "OBJ";
                }
                if (adlim.test(value) == true) {
                  return "REJ";
                }
              }

    return null;
  }

}
