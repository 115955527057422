import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exclude'
})
export class ExcludePipe implements PipeTransform {

  transform(wordstring: string, excludestring?: string): string {
    let returnstring = wordstring.slice(0, wordstring.indexOf(excludestring) !== -1 ? wordstring.indexOf(excludestring) : wordstring.length);
    return returnstring;
  }

}
