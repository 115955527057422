import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArray'
})
export class ToArrayPipe implements PipeTransform {
  arr: any;
  transform(value: any, args?: any): any {
    this.arr = [];
    for (let object of value) {
      // console.log(object)
      // console.log(value)
      if (object !== undefined){
        this.arr.push(object);
      }
      else {
        this.arr.push({})
      }
    }
    return this.arr;
  }

}
