import { Title } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { MenuServiceService } from '../../shared/menu-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  message: string;

  constructor(
    public authService: AuthService,
    public router: Router,
    public modalCtrl: ModalController,
    public authfire: AngularFireAuth,
    public menu: MenuServiceService,
    public title: Title
    // public fireauth: auth
    ) {
      this.title.setTitle('Login - PatentPhD')
    this.setMessage();
  }

  setMessage() {
    this.message = 'Logged ' + (this.authService.isLoggedIn() ? 'in' : 'out');
  }

  login(email, password) {
    this.message = 'Trying to log in ...';

    this.authService.login(email, password).then((what) => {
      console.log('what');
      console.log(what);
      what.subscribe(() => {
      this.setMessage();
      if (this.authService.isLoggedIn()) {
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        const redirect = this.authService.redirectUrl
          ? this.router.parseUrl(this.authService.redirectUrl)
          : '/admin';

        // Redirect the user
        this.router.navigateByUrl(this.authService.redirectUrl);
        console.log(this.authService.redirectUrl);
        this.dismiss();
      }
    });

     }
    );
  }

  logout() {
    this.authService.logout();
    this.setMessage();
  }
  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
  toggle() {
    this.menu.toggleMenu()
  }
}
