import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applFormat'
})
export class ApplFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // Allow both strings & numbers but convert to String
    let v = String(value)
    // Get 2 digit Prefix
    let a = v.slice(0,2)
    // get 3 digit Middle
    let b = v.slice(2, v.length - 3)
    // get 3 digit suffix
    let c = v.slice(v.length - 3, v.length)

    return a + '/' + b + ',' + c;
  }

}
