import { DocumentFilterPipe, DocFilterPipe } from './document-filter.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToArrayPipe } from './to-array.pipe';
import { ShortStatusPipe } from './short-status.pipe';
import { CssifyPipe } from './cssify.pipe';
import { HighlightPipe } from './highlight.pipe';
import { ApplFormatPipe } from './appl-format.pipe';
import { ExcludePipe } from './exclude.pipe';

@NgModule({
  declarations: [DocumentFilterPipe, DocFilterPipe, ToArrayPipe, ShortStatusPipe, CssifyPipe, HighlightPipe, ApplFormatPipe, ExcludePipe],
  imports: [
    CommonModule
  ],
  exports:[DocumentFilterPipe, DocFilterPipe, ToArrayPipe, ShortStatusPipe, CssifyPipe, HighlightPipe, ApplFormatPipe, ExcludePipe]
})
export class PipesModule { }
